<template>
  <div>
    <b-row class="title-frame">
      <b-col class="sub-title">
        Summary
      </b-col>
    </b-row>
    <hr class="divider">
    <b-row class="summary-title">
      <b-col cols="6">Subtotal</b-col>
      <b-col cols="6" class="text-right">
        {{ payment.subtotal | priceFormat }}
      </b-col>
    </b-row>
    <b-row class="summary-title">
      <b-col cols="6">Delivery Fee</b-col>
      <b-col cols="6" class="text-right">
        {{ payment.deliveryFee | priceFormat }}
      </b-col>
    </b-row>
    <b-row class="summary-title">
      <b-col cols="6">Tax</b-col>
      <b-col cols="6" class="text-right">
        {{ payment.tax | priceFormat }}
      </b-col>
    </b-row>
    <b-row class="summary-title-Total mb-3">
      <b-col cols="6">Total</b-col>
      <b-col cols="6" class="text-right">
        {{ payment.total | priceFormat }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ShoppingBagPaymentSummary",
  computed: {
    ...mapGetters({
      payment: 'cart/payment',
    }),
  },
}
</script>

<style scoped>
.title-frame {
  height: 60px;
}
.sub-title {
  font-size: 30px;
  font-weight: 700;
  text-align: left;
  margin-top: auto;
}
.divider {
  height: .20rem;
  background: #1c1c1c;
  border: none;
  margin: 0 0 10px 0;
}
.summary-title {
  text-align: left;
  font-size: 20px;
  font-weight: 500;
}
.summary-title-Total {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
}

.checkout-font {
  font-size: 20px;
  font-weight: 600;
  vertical-align: sub;
  margin:0 0 0 5px;
}
</style>
