<template>
  <b-container fluid>
    <HomeNavigationBar v-bind:isOpen="isOpen" />
    <FullScreenImage id="Home"/>
    <HomeMenu v-bind:isOpen="isOpen" />
    <HomeContact id="Contact"/>
  </b-container>
</template>

<script>
import HomeNavigationBar from '@/components/Home/HomeNavigationBar'
import HomeMenu from '@/components/Home/HomeMenu'
import HomeContact from '@/components/Home/HomeContact'
import moment from 'moment'
import FullScreenImage from "@/components/ToolBox/FullScreenImage";

export default {
  name: "Home",
    components: {
      FullScreenImage,
      HomeNavigationBar,
      HomeMenu,
      HomeContact
    },
  data() {
    return {
      now: moment(),
      dayOfWeek: moment().day(),
      isOpen: false,
      closedDate: [
        moment("12-25", "MM-DD").format("MM-DD"),
        moment("01-01", "MM-DD").format("MM-DD")
      ],
      today: moment(new Date()).format("MM-DD")
    }
  },
  mounted() {
    // Check Store Operating Hours
    this.isOpen =
      this.now.isAfter(moment(this.constant.store.operatingHours[this.dayOfWeek].open, 'HH:mm'))
      && this.now.isBefore(moment(this.constant.store.operatingHours[this.dayOfWeek].close, 'HH:mm'))
      && !this.closedDate.includes(this.today)
  },
  // created() {
  //
  //   console.log(this.closedDate[1]);
  //   console.log(this.today);
  // }
}
</script>

<style scoped>

</style>
