<template>
  <b-row>
    <b-col :class="$style.image">
      <MouseScrollAnimation />
    </b-col>
  </b-row>
</template>

<script>
import MouseScrollAnimation from "@/components/ToolBox/MouseScrollAnimation";
export default {
  name: "FullScreenImage",
  components: {MouseScrollAnimation},
}
</script>

<style module lang="scss">
  $-full-screen-width: 100vw;
  $-full-screen-height: 100vh;
  $-center-x-position: 80%;
  $-center-y-position: 80%;

  .image {
    width: $-full-screen-width;
    height: $-full-screen-height;
    background-image: url("~@/assets/image/Title.jpg");
    background-position: $-center-x-position $-center-y-position;
    background-size: cover;
  }
</style>
