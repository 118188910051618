<template>
  <b-container fluid id="confirmOrder">
    <!-- Spinner -->
    <ConfirmOrderNavigationBar />
    <ConfirmOrderSpinner />
    <!-- Order Confirmation -->
    <b-row class="background-grey text-left">
      <b-col>
        <b-row>
          <b-col class="mt-3 title">Order Confirmation</b-col>
        </b-row>
        <hr class="divider">

        <!-- Info -->
        <ConfirmOrderInfo
          v-bind:info="form.order.info"
          v-bind:paymentMethod="form.order.paymentMethod"
          v-bind:operationMode="form.order.operationMode"
        />

        <!-- Payment Summary -->
        <ConfirmOrderPayment v-bind:payment="form.order.payment" />

        <!-- Item Table -->
        <ConfirmOrderItemsTable v-bind:orderItems="form.order.orderItems" />

        <!-- tip -->
        <ConfirmOrderTips />

        <!-- Stripe Payment -->
        <div v-if="form.order.paymentMethod === 'ONLINE'">
          <b-row class="mb-2">
            <b-col cols="10" md="4" class="m-auto">
              <b-img :src="getAsset('stripeBadgeWhite.png')" fluid/>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="m-auto">
              <stripe-element-card
                  ref="stripeCard"
                  :pk="this.appConfig.stripe.PublishableKey"
                  @token="stripeTokenCreated"
              />
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col cols="auto" class="m-auto">
              <b-button block variant="danger"
                  :disabled="this.orderInProcess"
                  @click="stripeGenerateCardToken">
                <span class="h3 px-3"><b>Place Order</b></span>
              </b-button>
            </b-col>
          </b-row>
        </div>

        <!-- Place Order Button -->
        <b-row v-else-if="form.order.paymentMethod === 'STORE'" class="mb-3">
          <b-col cols="auto" class="m-auto">
            <b-button block variant="danger"
                :disabled="this.orderInProcess"
                @click="this.$store.dispatch('cart/placeOrder');">
              <span class="h3 px-3"><b>Place Order</b></span>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import ConfirmOrderNavigationBar from "@/components/ConfirmOrder/ConfirmOrderNavigationBar";
import {mapGetters} from "vuex";
import ConfirmOrderSpinner from "@/components/ConfirmOrder/ConfirmOrderSpinner";
import ConfirmOrderPayment from "@/components/ConfirmOrder/ConfirmOrderPayment";
import ConfirmOrderInfo from "@/components/ConfirmOrder/ConfirmOrderInfo";
import ConfirmOrderItemsTable from "@/components/ConfirmOrder/ConfirmOrderItemsTable";
import ConfirmOrderTips from "@/components/ConfirmOrder/ConfirmOrderTips";

export default {
  name: "ConfirmOrder",
  components: {
    ConfirmOrderTips,
    ConfirmOrderItemsTable,
    ConfirmOrderInfo,
    ConfirmOrderPayment,
    ConfirmOrderSpinner,
    ConfirmOrderNavigationBar,
    StripeElementCard,
  },
  data() {
    return {
      form: {
        businessId: this.appConfig.store.businessId,
        order: {
          paymentMethod: this.$store.getters['cart/paymentMethod'],
          payment: this.$store.getters['cart/payment'],
          operationMode: this.$store.getters['cart/operationMode'],
          info: this.$store.getters['cart/info'],
          orderItems: this.$store.getters['cart/cart'],
          stripeToken: null,
        }
      },
    }
  },
  created() {
    this.$store.commit('cart/updateOrderInProcess', false);
  },
  computed: {
    ...mapGetters({
      orderInProcess: 'cart/orderInProcess',
    })
  },
  methods: {
    stripeGenerateCardToken () {
      this.$store.commit('cart/updateOrderInProcess', true);
      //4242 4242 4242 4242
      // this will trigger the process
      this.$refs.stripeCard.submit();
    },
    stripeTokenCreated (token) {
      // handle the token
      // send it to your server
      this.$store.commit('cart/updateStripeToken', token.id);
      const promise = this.$store.dispatch('cart/placeOrder');
      promise.then(value => {
        if(value.status === 201) {
          this.$store.commit('cart/updateOrderInProcess', false);
          this.$store.commit('cart/resetState');
          this.$alert(
              'You Order #' + value.data.orderNumber + ' will be ready within shortly!',
              'Order Placed!',
              'success'
          );
          this.$router.push('/');
        } else {
          this.$alert(
              value.data.errorMessage,
              'Failed!',
              'error'
          );
          this.$store.commit('cart/updateOrderInProcess', false);
        }
      });
    },
  }
}
</script>

<style scoped>
  .background-grey {
    background-color: #fafafa;
  }
  .title {
    font-size: 40px;
    font-weight: 700;
    text-align: left;
    margin-top: auto;
  }
  .divider {
    height: .25rem;
    background: #000;
    border: none;
    margin: 0 0 20px 0;
  }

</style>
