import Vue from "vue";

Vue.filter('reverse', function(value) {
    // slice to make a copy of array, then reverse the copy
    return value.slice().reverse();
})

Vue.filter('priceFormat', function(num) {
    num = num.toString()
    let len = num.length
    if (len === 1) return '$0.' + num + '0'
    else if (len === 2) return '$0.' + num
    else return '$' + num.substring(0, len-2) + '.' + num.substring(len-2)
})

Vue.filter('decimalToPercentageFormat', function(num) {
    return num*100 + '%';
})
