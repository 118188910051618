<template>
  <b-col
      v-if="item.isActive"
      cols="12" md="5" lg="4" class=" d-sm-mx-3 p-2 mb-2 mx-auto">
    <b-card no-body class="h-100 overflow-hidden text-left shadow-sm">
      <b-row no-gutters class="mb-3">
        <b-col cols="12" :class="$style['img-holder']">
          <b-card-img-lazy
            v-if="this.item.image.isActive"
            :src="this.item.image.src"
            :class="[$style['image'], 'rounded-0', 'w-100']"
            :center=true
          />
          <b-card-img-lazy
            v-else
            :src="require('@/assets/image/PictureComingSoon.png')"
            :class="[$style['image'], 'rounded-0', 'w-100']"
          />
        </b-col>
      </b-row>
      <!-- Product Info -->
      <b-row no-gutters class="mb-3">
        <b-col cols="12">
          <b-row>
            <b-col class="h6 mb-0 mx-3"><b>{{ item.productName }}</b></b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col class="mx-3">{{ item.translations.zh }}</b-col>
          </b-row>
          <b-row >
            <b-col class="mx-3">{{ item.productDescription }}</b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- Price & Quantity & Button -->
      <b-row no-gutters class="mt-auto">
        <b-col cols="12">
          <b-row class="mb-1">
            <b-col class="ml-3"><b>{{ item.basePrice | priceFormat }}</b></b-col>
            <b-col class="mr-3 text-right" v-if="isSelected">
              Quantity: {{ quantity }}
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col class="mx-3">
              <b-button
                  v-if="this.appConfig.store.hasPayment"
                  block
                  :variant="isSelectedButton"
                  class="align-content-center"
                  v-on:click="addItem()"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-bag-plus" viewBox="0 0 16 16" style="margin-bottom: 4px;">
                  <path fill-rule="evenodd" d="M8 7.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0v-1.5H6a.5.5 0 0 1 0-1h1.5V8a.5.5 0 0 1 .5-.5z"/>
                  <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>
                </svg>
                <span style="margin-left: 3px;">{{ isSelectedContent }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>

<script>
  export default {
    name: "HomeMenuItem",
    props: {
      item: {
        isActive: Boolean,
        businessId: String,
        productId: String,
        productName: String,
        category: {
          id: String,
          name: String
        },
        image: {
          isActive: Boolean,
          src: String
        },
        basePrice: Number,
        productDescription: String,
        translations: {zh: String},
      },
      isOpen: Boolean
    },
    computed: {
      isSelected() {return this.$store.getters['cart/isProductSelected'](this.item.productId)},
      quantity() {return this.$store.getters['cart/productQuantity'](this.item.productId)},
      isSelectedButton() {return this.isSelected ? 'danger' : 'outline-danger'},
      isSelectedContent() {return this.isSelected ? 'Add One More' : 'Add to Bag'},
    },
    methods: {
      addItem() {
        if (this.isOpen) {
          this.$store.dispatch('cart/addProductToCart', this.item)
        } else {
          this.showStoreClosedAlert();
        }
      },
      showStoreClosedAlert() {
        const aClosed = this.constant.alert.storeClosed
        this.$alert(aClosed.msg, aClosed.title, aClosed.type);
      }
    }
  }
</script>

<style module lang="scss">
  .img-holder {
    overflow: hidden;
  }

  .image {

    &:hover {
      transition-duration: 1s;
      transform: scale(1.1, 1.1);
      -ms-transform: scale(1.1, 1.1);
      -webkit-transform: scale(1.1, 1.1);
    }
  }
</style>
