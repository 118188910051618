<template>
  <b-row class="menu px-0 pb-3 my-3 mx-auto">
    <b-col>
      <HomeMenuSpecial />
      <b-row>
        <b-col>
          <b-row id="Menu" class="mt-5 mb-3">
            <b-col class="col-auto h2 m-auto">
              MENU | 菜单
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="3">
              <HomeMenuCategoryList />
            </b-col>
            <b-col cols="12" md="9">
              <b-row id="MenuItems" class="my-2 mx-auto">
                <b-col class="text-left h3">
                  <span>{{ this.activeCategory.categoryName }}</span> | <span>{{ this.activeCategory.translations.zh }}</span>
                </b-col>
              </b-row>
              <b-row>
                <HomeMenuItem
                    v-for="item in this.activeCategoryItems" :key="item.productId"
                    v-bind:item = item
                    v-bind:isOpen="isOpen"
                />
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
  import HomeMenuSpecial from '@/components/Home/HomeMenuSpecial'
  import HomeMenuItem from '@/components/Home/HomeMenuItem'
  import HomeMenuCategoryList from "@/components/Home/HomeMenuCategoryList";
  import {mapGetters} from "vuex";

  export default {
    name: "HomeMenu",
    components: {
      HomeMenuCategoryList,
      HomeMenuSpecial,
      HomeMenuItem,
    },
    props: {isOpen: Boolean},
    computed: {
      ...mapGetters({
        activeCategory: 'menu/activeCategory',
        activeCategoryItems: 'menu/activeCategoryItems',
      })
    },
    mounted() {
      this.$store.dispatch('menu/getActiveCategoryItems');
    },
  }
</script>

<style>
  .menu {
    z-index: 100;
    width: 96%;
    background: #fff;
    box-shadow: 0 0 40px rgba(0,0,0,.4);
  }
</style>
