<template>
  <div>
    <b-row class="title-frame">
      <b-col class="sub-title">
        Contact
      </b-col>
    </b-row>
    <hr class="divider">
    <b-row class="text-left">
      <b-col>
        <b-form-group
          id="input-group-1"
          label="Phone Number*:"
          label-for="phone"
          description="We will contact you only for ordering issue."
        >
          <b-form-input
            id="phone"
            v-model="phone"
            type="number"
            placeholder="Enter phone number"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="text-left">
      <b-col>
        <b-form-group
          id="input-group-1"
          label="Comment:"
          label-for="comment"
          description=""
        >
          <b-form-input
            id="comment"
            v-model="comment"
            type="tel"
            placeholder="(Optional)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "ShoppingBagContact",
  data() {
    return {
      phone: this.$store.getters['cart/info'].phone,
      comment: this.$store.getters['cart/info'].comment,
    }
  },
  watch: {
    phone: function (val) {this.$store.commit('cart/updatePhone', val)},
    comment: function (val) {this.$store.commit('cart/updateComment', val)},
  }
}
</script>

<style scoped>
.title-frame {
  height: 60px;
}
.sub-title {
  font-size: 30px;
  font-weight: 700;
  text-align: left;
  margin-top: auto;
}
.divider {
  height: .20rem;
  background: #1c1c1c;
  border: none;
  margin: 0 0 10px 0;
}
</style>
