// Sample Code: https://github.com/vuejs/vuex/tree/dev/examples/shopping-cart

import axios from "axios";
import {config} from '@/config';

const DEFAULT_CART = [];
const DEFAULT_USE_COUPON = false;
const DEFAULT_COUPON_ITEM = {
    basePrice: 0,
    isActive: true,
    productDescription: "Prawn tempura and cucumber topped with avocado slices and masago",
    translations: {
      zh: "溫西炸蝦卷 (免費）"
    },
    image: {
      isActive: true,
      src: "https://d31c8n1eaj1ecj.cloudfront.net/2dd81cdc-67f6-55c7-bad1-39442696e392.jpg",
    },
    category: {
      id: "ca1f4c68-0258-5074-8b03-873652d8e99e",
      name: "Famous Roll"
    },
    productId: "597bfb9b-b322-5b18-ad77-9dc0f75e753c",
    productName: "Kerrisdale Roll (Free)",
    quantity: 1
  };
const DEFAULT_INFO = {
  phone: '',
  delivery: {
    address: '',
    aptNumber: ''
  },
  comment: '',
};
const DEFAULT_PAYMENT = {
  subtotal: 0,
  deliveryFee: 0,
  gst: 0,
  pst: 0,
  tax: 0,
  total: 0,
  tip: 0,
};
const DEFAULT_TIP_PERCENTAGE = 0.15;
const DEFAULT_PAYMENT_METHOD = 'ONLINE';
const DEFAULT_OPERATION_MODE = 'PICK_UP';
const DEFAULT_ORDER_IN_PROCESS = false;
const DEFAULT_STRIPE_TOKEN = null;


const state = {
  cart:             DEFAULT_CART,
  useCoupon:        DEFAULT_USE_COUPON,
  couponItem:       DEFAULT_COUPON_ITEM,
  info:             DEFAULT_INFO,
  payment:          DEFAULT_PAYMENT,
  tipPercentage:    DEFAULT_TIP_PERCENTAGE,
  paymentMethod:    DEFAULT_PAYMENT_METHOD,
  operationMode:    DEFAULT_OPERATION_MODE,
  orderInProcess:   DEFAULT_ORDER_IN_PROCESS,
  stripeToken:      DEFAULT_STRIPE_TOKEN,
};

const getters = {
  cart: state => state.cart,
  cartCounter: state => {
    let counter = 0
    state.cart.map(p => counter += p.quantity)
    return counter
  },
  isProductSelected: state => (pId) => {
    return state.cart.find(p => p.productId === pId) !== undefined
  },
  productQuantity: state => (pId) => {
    const pInfo = state.cart.find(p => p.productId === pId)
    return (pInfo === undefined) ? 0 : pInfo.quantity
  },
  payment: state => state.payment,
  tipPercentage: state => state.tipPercentage,
  info : state => state.info,
  operationMode: state => state.operationMode,
  paymentMethod: state => state.paymentMethod,
  useCoupon: state => state.useCoupon,
  couponItem: state => state.couponItem,
  orderInProcess: state => state.orderInProcess,
};

const actions = {
  addProductToCart ({ state, commit, dispatch }, product) {
    const isCartProductExist = state.cart.find(p => p.productId === product.productId);
    if (!isCartProductExist) {
      product.quantity = 1;
      commit('pushProductToCart', product);
    } else {
      commit('incrementProductQuantity', product.productId);
    }
    dispatch('updatePayment');
  },
  removeProductFromCart ({ state, commit, dispatch }, product) {
    const isCartProductExist = state.cart.find(p => p.productId === product.productId);
    if (isCartProductExist) {
      commit('removeProduct', product);
      dispatch('updatePayment');
    }
  },
  updateProductQuantity ({ state, commit, dispatch }, product) {
    const isCartProductExist = state.cart.find(p => p.productId === product.productId);
    if (isCartProductExist) {
      commit('updateQuantity', product);
      dispatch('updatePayment');
    }
  },
  updateDeliveryFee ({ dispatch, commit }, deliveryFee) {
    commit('updateDeliveryFee', deliveryFee);
    dispatch('updatePayment');
  },
  updatePayment ({ state, commit }) {
    let pay = {
      subtotal: 0,
      deliveryFee: 0,
      gst: 0,
      pst: 0,
      tax: 0,
      total: 0,
      tip: 0,
    };
    state.cart.map(p => pay.subtotal+= p.quantity * p.basePrice);
    pay.deliveryFee = state.payment.deliveryFee;
    pay.gst = Math.round((pay.subtotal + pay.deliveryFee) * 0.05);
    // No PST for restaurant
    pay.tax = pay.gst + pay.pst;
    pay.total = (pay.subtotal + pay.deliveryFee + pay.tax);
    commit('updatePayment', pay);
  },
  async placeOrder({ state, commit }) {
    let form = {
      businessId: config.store.businessId,
      order: {
        paymentMethod: state.paymentMethod,
        payment: state.payment,
        operationMode: state.operationMode,
        info: state.info,
        orderItems: state.cart,
        stripeToken: state.stripeToken,
      }
    }
    commit('updateOrderInProcess', true);
    return new Promise(resolve => {
      axios({
        method: 'post',
        url: config.michaelxyz.api.order,
        headers: {'Content-Type': 'application/json'},
        data: JSON.stringify(form)
      }).then(res => {
        resolve(res);
      }).catch(err => resolve(err));
    })
  }
};

const mutations = {
  pushProductToCart (state, product) {
    state.cart.push(product);
  },
  incrementProductQuantity (state, pId) {
    const product = state.cart.find(p => p.productId === pId);
    product.quantity++;
    const temp = state.cart;
    state.cart = [];
    state.cart = temp;
  },
  updateQuantity (state, product) {
    const pro = state.cart.find(p => p.productId === product.productId);
    pro.quantity = product.quantity;
    const temp = state.cart;
    state.cart = [];
    state.cart = temp;
  },
  removeProduct (state, product) {
    state.cart = state.cart.filter(p => p !== product);
  },
  updatePhone (state, phone) {state.info.phone = phone},
  updateComment (state, comment) {state.info.comment = comment},
  updateOperationMode (state, operationMode) {state.operationMode = operationMode},
  updatePaymentMethod (state, paymentMethod) {state.paymentMethod = paymentMethod},
  updatePayment (state, payment) {state.payment = payment},
  updateTip (state, tip) {state.payment.tip = tip},
  updateTipPercentage (state, tipPercentage) {state.tipPercentage = tipPercentage},
  updateDeliveryAddress (state, address) {state.info.delivery.address = address},
  updateDeliveryApt (state, apt) {state.info.delivery.aptNumber = apt},
  updateDeliveryFee (state, fee) {state.payment.deliveryFee = fee},
  updateUseCoupon (state, useCoupon) {
    state.useCoupon = useCoupon
    if (useCoupon === "true") {
      state.cart.unshift(state.couponItem)
    } else {
      const index = state.cart.findIndex(item => item.productId === state.couponItem.productId)
      if (index > -1) state.cart.splice(index, 1)
    }
  },
  resetState (state) {
    state.cart = [];
    state.useCoupon = false;
    state.info = {
      phone: null,
      delivery: {
        address: '',
        aptNumber: ''
      },
      comment: '',
    };
    state.operationMode = 'PICK_UP';
    state.paymentMethod = 'ONLINE';
  },
  updateOrderInProcess (state, isInProcess) { state.orderInProcess = isInProcess;},
  updateStripeToken (state, stripeToken) { state.stripeToken = stripeToken;},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
