<template>
  <b-navbar toggleable="md">
    <!-- Shota Logo -->
    <b-navbar-brand href="#">
      <b-img src="@/assets/image/LogoLandscape.png"/>
    </b-navbar-brand>

    <!-- Collapse Nav Item -->
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav >
        <b-nav-item href="#Home">Home</b-nav-item>
        <b-nav-item href="#Special">Special</b-nav-item>
        <b-nav-item href="#Menu">Menu</b-nav-item>
        <b-nav-item href="#Contact">Contact</b-nav-item>
      </b-navbar-nav>
    </b-collapse>

    <!-- Shopping Cart Icon -->
    <b-navbar-nav right>
      <b-nav-item @click="routeToShoppingBag">
        <b-icon-cart2 scale="1.5" />
        <b-badge v-if="cartCounter !== 0" :class="$style.badge" variant="danger" pill>
          {{ cartCounter }}
        </b-badge>
      </b-nav-item>
    </b-navbar-nav>

  </b-navbar>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  name: "HomeNavigationBar",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      now: moment(),
      dayOfWeek: moment().day(),
    }
  },
  computed: {
    ...mapGetters({
      cartCounter : 'cart/cartCounter',
    }),
  },
  methods: {
    routeToShoppingBag() {
      if (this.isOpen) {
        this.$router.push('/myBag#bag')
      } else {
        this.showStoreClosedAlert()
      }
    },
    showStoreClosedAlert() {
      const aClosed = this.constant.alert.storeClosed
      this.$alert(aClosed.msg, aClosed.title, aClosed.type);
    }
  }
}
</script>

<style module lang="scss">
  .badge {
    margin-left: -6pt;
    font-size: 0.8rem;
  }
</style>
