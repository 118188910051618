// https://router.vuejs.org/
import VueRouter from 'vue-router'
import Home from '@/components/Home/Home'
import ShoppingBag from '@/components/ShoppingBag/ShoppingBag'
import ConfirmOrder from '@/components/ConfirmOrder/ConfirmOrder'
import Vue from "vue";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: function (to) {
        if (to.hash) {
            return {
                selector: to.hash
            }
        }
    },
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
            meta: {
                title: 'Home'
            }
        }, {
            path: '/myBag',
            name: 'MyBag',
            component: ShoppingBag,
            meta: {
                title: 'My Bag'
            }
        }, {
            path: '/confirmOrder',
            name: 'ConfirmOrder',
            component: ConfirmOrder,
            meta: {
                title: 'Confirm Order'
            }
        }
    ]
})

// Add Tab Title
router.beforeEach((to, from, next) => {
    document.title = to.meta.title + ' | Shota Sushi & Grill'
    next()
})

export default router
