<template>
  <b-row :class="$style['grey-out']" :hidden="!this.orderInProcess">
    <b-col cols="auto" class="my-auto ml-auto">
      <atom-spinner
          :animation-duration="2000"
          :size="60"
          :color="'#ff0000'"
      />
    </b-col>
    <b-col cols="auto" class="my-auto">
      <atom-spinner
          :animation-duration="3000"
          :size="120"
          :color="'#ff0000'"
      />
    </b-col>
    <b-col cols="auto" class="my-auto mr-auto">
      <atom-spinner
          :animation-duration="2000"
          :size="60"
          :color="'#ff0000'"
      />
    </b-col>
  </b-row>
</template>

<script>
import {mapGetters} from "vuex";
import {AtomSpinner} from "epic-spinners";

export default {
  name: "ConfirmOrderSpinner",
  components: {
    AtomSpinner,
  },
  computed: {
    ...mapGetters({
      orderInProcess: 'cart/orderInProcess'
    }),
  }
}
</script>

<style module lang="scss">
.grey-out {
  z-index: 1000;
  position: fixed;
  top:0;
  left: 0;
  width:105%;
  height:100vh;
  background: #020000;
  opacity: 30%;
}
</style>
