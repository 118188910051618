import Vue from 'vue'
import cart from './modules/cart'
import menu from './modules/menu'
//https://github.com/robinvdvleuten/vuex-persistedstate
import createPersistedState from 'vuex-persistedstate'

//https://vuex.vuejs.org/
import Vuex from "vuex"
Vue.use(Vuex)

export default new Vuex.Store({
    plugins:[createPersistedState()],
    modules: {
        cart,
        menu,
    },
})
