<template>
  <b-navbar>
    <!-- Shota Logo -->
    <b-navbar-brand href="#">
      <router-link v-bind:to="{ name: 'Home', params:{'id': '#home'}}">
        <b-img src="@/assets/image/LogoLandscape.png"/>
      </router-link>
    </b-navbar-brand>

    <!-- Bag Counter -->
    <div class="ml-auto">
      <span class="h4">Bag</span>
      <span class="text-dark"> ({{ cartCounter }} items)</span>
    </div>
  </b-navbar>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ConfirmOrderNavigationBar",
  computed: {
    ...mapGetters({
      cartCounter : 'cart/cartCounter',
    }),
  },
  methods: {
    routeToShoppingBag() {
      if (this.isOpen) {
        this.$router.push('/myBag#bag')
      } else {
        this.showStoreClosedAlert()
      }
    },
  }
}
</script>

<style scoped>

</style>
