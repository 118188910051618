import axios from "axios";
import {config} from '@/config';

const DEFAULT_CATEGORY_LIST = [];
const DEFAULT_CATEGORY_COUNT = 0;
const DEFAULT_ACTIVE = {
  categoryId: 'f5bf5d96-4b1a-5e16-a6e9-c0b2615c1487',
  categoryName: 'Combo',
  translations: {zh: '套餐'},
};
const DEFAULT_ACTIVE_CATEGORY_ITEMS = [];

export default {
  namespaced: true,
  state: {
    categoryList: DEFAULT_CATEGORY_LIST,
    categoryCount: DEFAULT_CATEGORY_COUNT,
    activeCategory: DEFAULT_ACTIVE,
    activeCategoryItems: DEFAULT_ACTIVE_CATEGORY_ITEMS,
  },
  getters: {
    categoryList: state => state.categoryList,
    activeCategory: state => state.activeCategory,
    activeCategoryItems: state => state.activeCategoryItems,
  },
  actions: {
    getCategoryList ({ commit }) {
      axios({
        method: 'get',
        url: config.michaelxyz.api.category
          + '?businessId=' + config.store.businessId,
        headers: {'Content-Type': 'application/json'}
      }).then(res => {
        commit('updateCategoryList', res.data.Items);
        commit('updateCategoryCount', res.data.Count);
      }).catch(err => console.log(err))
    },
    updateActiveCategory ({dispatch, commit}, item) {
      commit('updateActiveCategory', item);
      dispatch('getActiveCategoryItems');
    },
    getActiveCategoryItems ({ state, commit }) {
      axios({
        method: 'get',
        url: config.michaelxyz.api.product + '?categoryId=' + state.activeCategory.categoryId,
        headers: {'Content-Type': 'application/json'}
      }).then(res => {
        commit('updateActiveCategoryItems', res.data.Items);
      }).catch(err => console.log(err))
    },
  },
  mutations: {
    updateCategoryList (state, items) {state.categoryList = items},
    updateCategoryCount (state, count) {state.categoryCount = count},
    updateActiveCategory (state, item) {state.activeCategory = item},
    updateActiveCategoryItems (state, items) {state.activeCategoryItems = items},
  }
}
