<template>
  <div v-if="this.product.basePrice === 0">
    <b-row class="mb-3 text-success">
      <b-col cols="6" md="3" class="pr-0">
        <b-img v-if="this.product.image.isActive"
               :src="this.product.image.src" fluid>
        </b-img>
        <b-img v-else
               :src="require('@/assets/image/PictureComingSoon.png')" fluid>
        </b-img>
      </b-col>
      <b-col class="text-left" cols="6" md="5">
        <b-row>
          <b-col class="title">
            {{ product.productName }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sub-title">
            {{ product.translations.zh }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            {{ product.productDescription }}
          </b-col>
        </b-row>
      </b-col>
      <b-col class="sub-title" cols="12" md="4">
        <b-row>
          <b-col>
            <b-row>
              <b-col>Price</b-col>
            </b-row>
            <b-row>
              <b-col>{{ product.basePrice | priceFormat }}</b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-col>Quantity</b-col>
            </b-row>
            <b-row>
              <b-col cols="auto m-auto">
                1
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col></b-col>
          <b-col class="remove-item mx-2 text-dark" v-on:click="removeFreeItem()" cols="auto">
            <b-icon-bag-x/> remove
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr>
  </div>
  <div v-else>
    <b-row class="mb-3">
      <b-col cols="6" md="3" class="pr-0">
        <b-img v-if="this.product.image.isActive"
               :src="this.product.image.src" fluid>
        </b-img>
        <b-img v-else
               :src="require('@/assets/image/PictureComingSoon.png')" fluid>
        </b-img>
      </b-col>
      <b-col class="text-left" cols="6" md="5">
        <b-row>
          <b-col class="title">
            {{ product.productName }}
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sub-title">
            {{ product.translations.zh }}
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            {{ product.productDescription }}
          </b-col>
        </b-row>
      </b-col>
      <b-col class="sub-title" cols="12" md="4">
        <b-row>
          <b-col>
            <b-row>
              <b-col>Price</b-col>
            </b-row>
            <b-row>
              <b-col>{{ product.basePrice | priceFormat }}</b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-col>Quantity</b-col>
            </b-row>
            <b-row>
              <b-col cols="auto m-auto">
                <b-form-select
                    v-model="quantity"
                    :options="quantityOptions"
                    size="sm"
                    value-field="item"
                    text-field="name"
                ></b-form-select>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col></b-col>
          <b-col class="remove-item mx-2" v-on:click="removeItem()" cols="auto">
            <b-icon-bag-x/> remove
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <hr>
  </div>
</template>

<script>
import BIcon from 'bootstrap-vue'
import Vue from "vue";

Vue.component('b-icon', BIcon)
export default {
  name: "ShoppingBagItem",
  props: {
    product: Object
  },
  data() {
    return {
      quantity: this.product.quantity,
      quantityOptions: [
        { item: 1, name: '1' },
        { item: 2, name: '2' },
        { item: 3, name: '3' },
        { item: 4, name: '4' },
        { item: 5, name: '5' },
        { item: 6, name: '6' },
        { item: 7, name: '7' },
        { item: 8, name: '8' },
        { item: 9, name: '9' },
        { item: 10, name: '10' },
        { item: 11, name: '11' },
        { item: 12, name: '12' },
        { item: 13, name: '13' },
        { item: 14, name: '14' },
        { item: 15, name: '15' },
        { item: 16, name: '16' },
        { item: 17, name: '17' },
        { item: 18, name: '18' },
        { item: 19, name: '19' },
        { item: 20, name: '20' },
      ]
    }
  },
  watch: {
    quantity: function(ele) {
      this.product.quantity = ele
      this.$store.dispatch('cart/updateProductQuantity', this.product )
    }
  },
  methods: {
    removeItem() {
      this.$store.dispatch('cart/removeProductFromCart', this.product)
    },
    removeFreeItem() {
      this.$store.dispatch('cart/removeProductFromCart', this.product)
      this.$store.commit('cart/updateUseCoupon', false)
    }
  }
}
</script>

<style scoped>
  .title {
    font-size: 20px;
    font-weight: 500;
  }
  .sub-title {
    font-size: 18px;
    font-weight: 450;
  }
  .remove-item:hover {
    color: #ff1744;
    cursor: pointer;
  }
</style>
