<template>
  <b-row>
    <b-col cols="12" md="6" class="ml-auto">
      <b-row :class="$style['sub-title']">
        <b-col class="pr-0"><small>Subtotal:</small></b-col>
        <b-col cols="4">
          <small>{{ payment.subtotal | priceFormat }}</small>
        </b-col>
      </b-row>
      <b-row :class="$style['sub-title']">
        <b-col class="pr-0"><small>Delivery Fee:</small></b-col>
        <b-col cols="4">
          <small>{{ payment.deliveryFee | priceFormat }}</small>
        </b-col>
      </b-row>
      <b-row :class="$style['sub-title']">
        <b-col class="pr-0"><small>Tax:</small></b-col>
        <b-col cols="4">
          <small>{{ payment.tax | priceFormat }}</small>
        </b-col>
      </b-row>
      <b-row :class="$style['sub-title']">
        <b-col class="pr-0"><b>Total:</b></b-col>
        <b-col cols="4">
          <b>{{ payment.total | priceFormat }}</b>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "ConfirmOrderPayment",
  props: {
    payment: {
      required: true,
      type: Object,
    },
  },
}
</script>

<style module lang="scss">
.sub-title {
  font-size: 25px;
  font-weight: 500;
  text-align: right;
  margin-top: auto;
}
</style>
