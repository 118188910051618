<template>
  <b-container id="bag" fluid>
    <ShoppingBagNavigationBar />
    <div class="mb-3">
      <b-row class="background-grey">
        <b-col id="myBag" cols="12" md="8">
          <b-row class="title-frame">
            <b-col class="title" cols="6" md="8">
              My Bag
            </b-col>
            <b-col class="sub-title sub-title-item" cols="6" md="4">
              {{ cartCounter }} Items
            </b-col>
          </b-row>
          <hr class="divider">
          <!-- Promo Option -->
          <ShoppingBagCoupon />
          <!-- products List -->
          <ShoppingBagItem
              v-for="(item, index) in cart" :key="index"
              v-bind:product="item"
          />
        </b-col>
        <b-col id="orderSummary" cols="12" md="4">
          <ShoppingBagInfo />
          <!-- Contact Info -->
          <ShoppingBagContact />
          <!-- Operation Method -->
          <ShoppingBagOperation />
          <!-- Payment Method -->
          <ShoppingBagPaymentMethod />
          <!-- Order Summary -->
          <ShoppingBagPaymentSummary />
          <!-- Check Out Button -->
          <b-row>
            <b-col>
              <b-button
                  block variant="danger"
                  v-on:click="checkout()">
                <div>
                  <b-icon-bag-check />
                  <span class="checkout-font">CHECKOUT</span>
                </div>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import {mapGetters} from "vuex";
import ShoppingBagCoupon from "@/components/ShoppingBag/ShoppingBagCoupon";
import ShoppingBagItem from "@/components/ShoppingBag/ShoppingBagItem"
import ShoppingBagInfo from "@/components/ShoppingBag/ShoppingBagInfo";
import ShoppingBagContact from "@/components/ShoppingBag/ShoppingBagContact";
import ShoppingBagOperation from "@/components/ShoppingBag/ShoppingBagOperation";
import ShoppingBagPaymentMethod from "@/components/ShoppingBag/ShoppingBagPaymentMethod";
import ShoppingBagPaymentSummary from "@/components/ShoppingBag/ShoppingBagPaymentSummary";

import Vue from 'vue'
import BIcon from 'bootstrap-vue'
import ShoppingBagNavigationBar from "@/components/ShoppingBag/ShoppingBagNavigationBar";

Vue.component('b-icon', BIcon)


export default {
  name: "ShoppingBag",
  components: {
    ShoppingBagNavigationBar,
    ShoppingBagCoupon,
    ShoppingBagPaymentSummary,
    ShoppingBagContact,
    ShoppingBagPaymentMethod,
    ShoppingBagOperation,
    ShoppingBagInfo,
    ShoppingBagItem,
  },
  data() {
    return {
      isValidAddress: false,
    }
  },
  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      cartCounter : 'cart/cartCounter',
      payment: 'cart/payment',
      info: 'cart/info',
      operationMode: 'cart/operationMode',
      paymentMethod: 'cart/paymentMethod',
    }),
  },
  methods: {
    checkout() {
      if (this.isOrderValid()) {
        if (this.useCoupon) {
          this.cart[this.couponItem.productId] = this.couponItem
          localStorage.setItem('cart', JSON.stringify(this.cart))
        }
        this.$router.push('/confirmOrder#confirmOrder')
      }
    },
    isOrderValid() {
      let i = null;
      // Validate phone number
      if (this.info.phone === "" || this.info.phone === null) {
        i = this.constant.alert.noPhone
      }
      // Validate address
      if (this.operationMode === 'DELIVERY' && this.info.delivery.address.length === 0) {
        i = this.constant.alert.invalidAddress
      }

      // Validate operation mode
      if (this.operationMode === 'DELIVERY' && this.payment.subtotal < 3000) {
        i = this.constant.alert.minimumOrder
      }

      // Validate bag
      if (this.cartCounter === 0) {
        i = this.constant.alert.noItemInBag
      }

      if (i === null) {
        return true;
      } else {
        this.$alert(i.msg, i.title, i.type)
        return false
      }

    },
  }
}
</script>

<style >
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

 .background-grey {
   background-color: #fafafa;
 }
 .title-frame {
   height: 60px;
 }
 .title {
   font-size: 40px;
   font-weight: 700;
   text-align: left;
   margin-top: auto;
 }
 .sub-title {
   font-size: 30px;
   font-weight: 700;
   text-align: left;
   margin-top: auto;
 }
 .sub-title-item {
   text-align: right;
 }
 .divider {
   height: .20rem;
   background: #1c1c1c;
   border: none;
   margin: 0 0 10px 0;
 }
 .checkout-font {
   font-size: 20px;
   font-weight: 600;
   vertical-align: sub;
   margin:0 0 0 5px;
 }

</style>
