<template>
  <div>
    <b-row class="title-frame">
      <b-col class="sub-title">
        Payment
      </b-col>
    </b-row>
    <hr class="divider">
    <b-row class="text-left">
      <b-col>
        <b-form-group>
          <b-form-radio
            v-model="paymentMethod"
            value="STORE"
            size="lg"
            :inline="true"
            disabled
          >
            Store Payment
          </b-form-radio>
          <b-form-radio
            v-model="paymentMethod"
            value="ONLINE"
            size="lg"
            :inline="true"
          >
            Online Payment
          </b-form-radio>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "ShoppingBagPaymentMethod",
  data() {
    return {
      paymentMethod: this.$store.getters['cart/paymentMethod'], // 'IN_STORE' && 'ONLINE'
    }
  },
  watch: {
    paymentMethod: function (val) {
      this.$store.commit('cart/updatePaymentMethod', val)
    }
  }
}
</script>

<style scoped>
.title-frame {
  height: 60px;
}
.sub-title {
  font-size: 30px;
  font-weight: 700;
  text-align: left;
  margin-top: auto;
}
.divider {
  height: .20rem;
  background: #1c1c1c;
  border: none;
  margin: 0 0 10px 0;
}
</style>
