<template>
  <b-row class="text-left">
    <b-col>
      <b-form-group label="Unit #:" label-for="addressUnit">
        <b-form-input
          id="addressUnit"
          v-model="aptNumber"
          type="text"
          placeholder="(Optional)"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Address*:" label-for="address">
        <vue-google-autocomplete
          ref="address"
          id="map"
          v-model="address"
          classname="form-control"
          placeholder="Enter Address Here"
          country="ca"
          v-on:placechanged="getAddressData"
          v-on:change="addressChanged"
          />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
  name: "GoogleMapAutoCompleteComponent",
  components: { VueGoogleAutocomplete },
  data() {
    return {
      fee: this.$store.getters['cart/payment'].deliveryFee,
      aptNumber: this.$store.getters['cart/info'].delivery.aptNumber,
      address: this.$store.getters['cart/info'].delivery.address,
      isValidAddress: false,
    }
  },
  watch: {
    //fee: function (val) {this.$store.dispatch('cart/updateDeliveryFee', val)},
    aptNumber: function (val) {this.$store.commit('cart/updateDeliveryApt', val)},
    address: function (val) {this.$store.commit('cart/updateDeliveryAddress', val)},
  },
  methods: {
    addressChanged: function (val) {
      this.address = val;
      if (this.address === '') {
        this.fee = 0
      }
    },
    getAddressData: function (addressObj) {
      this.address =
          addressObj.street_number + ' '
        + addressObj.route + ', '
        + addressObj.locality + ', '
        + addressObj.postal_code + ' '
        + addressObj.country

      this.deliveryFeeCalculator(addressObj)
    },
    deliveryFeeCalculator(addressObj) {
      let distance = this.getDistanceFromLatLonInKm(this.constant.store.lat, this.constant.store.lon, addressObj.latitude, addressObj.longitude)

      if (distance <= 3) {
        this.isValidAddress = true
        //this.fee = 400
      }
      else if (distance <= 5) {
        this.isValidAddress = true
        //this.fee = 400
      }
      else {
        this.isValidAddress = false
        let unDeliverable = this.constant.alert.unDeliverable
        this.$alert(
            unDeliverable.msg,
            unDeliverable.title,
            unDeliverable.type
        ).then(() => {
          this.$refs.address.clear()
          this.address = ''
        });
      }
    },
    getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
      let R = 6371; // Radius of the earth in km
      let dLat = this.deg2rad(lat2-lat1)  // deg2rad below
      let dLon = this.deg2rad(lon2-lon1)
      let a =
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon/2) * Math.sin(dLon/2)
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
      return R * c // Distance in km
    },
    deg2rad(deg) {return deg * (Math.PI/180)}
  }
}
</script>

<style scoped>

</style>
