<template>
  <b-row class="my-3">
    <b-col>
      <b-table :fields="productListFields" :items="orderItems">
        <template #cell(basePrice)="data">
          {{ data.item.basePrice | priceFormat }}
        </template>
      </b-table>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "ConfirmOrderItemsTable",
  props: {
    orderItems: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      productListFields: [
        { key: 'productName', label: 'Name' },
        { key: 'basePrice', label: 'Base Price' },
        { key: 'quantity', label: 'Quantity'},
        { key: 'productDescription', label: 'Description' }
      ],
    }
  },
}
</script>

<style scoped>

</style>
