<template>
  <b-row class="my-3">
    <b-img class="w-100 h-auto" fluid src="@/assets/image/ContactUs.jpg"/>
    <b-col >
      <b-row class="my-3">
        <b-col cols="12" md="6" style="min-height:300px;">
          <GoogleMapViewComponent />
        </b-col>
        <b-col cols="12" md="6" class="shadow">
          <b-row class="text-left">
            <b-col class="col-auto mt-2">
              <b-row>
                <b-col class="h4">
                  <b>ADDRESS</b>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col class="h5" v-b-hover="cursorHoverHandler">
                  <b-icon-cursor :scale="cursorIconScale"/> 5688 Yew St<br/>Vancouver, BC V6M 3Y3
                </b-col>
              </b-row>
              <b-row>
                <b-col class="h4">
                  <b>PHONE</b>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col class="h5" v-b-hover="phoneHoverHandler">
                  <b-icon-phone :scale="phoneIconScale"/> (604) 263-8068
                </b-col>
              </b-row>
              <b-row>
                <b-col class="h4">
                  <b>MEDIA</b>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col class="h3">
                  <b-icon-facebook
                      class="mr-3"
                      v-b-hover="fbHoverHandler"
                      :scale="fbIconScale"
                      @click="goToPage('https://www.facebook.com/shotasushi.ca')"/>
                  <b-icon-instagram
                      class="mr-3"
                      v-b-hover="insHoverHandler"
                      :scale="insIconScale"
                      @click="goToPage('https://www.instagram.com/shotasushi_vancity/')"/>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="col-auto mt-2">
              <b-row>
                <b-col class="h4 pr-0">
                  <b>BUSINESS HOURS</b>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-table-lite
                      :items="openHours"
                      thead-class="hidden_header"
                      tbody-class="h5"
                      :bordered=false
                      :borderless=true
                      :small=true
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Vue from 'vue'
import BIcon from 'bootstrap-vue'
import GoogleMapViewComponent from "@/components/Google/GoogleMapViewComponent";
Vue.component('b-icon', BIcon)

export default {
  name: "HomeContact",
  components: {GoogleMapViewComponent},
  data() {
    return {
      apiKey: this.appConfig.google.apiKey,
      map: {
        lat: this.constant.store.lat,
        lng: this.constant.store.lon,
        zoom: 17,
        type: 'roadmap'
      },
      openHours: [
        ['MON', '11 a.m. - 9 p.m.'],
        ['TUE', '11 a.m. - 9 p.m.'],
        ['WED', '11 a.m. - 9 p.m.'],
        ['THU', '11 a.m. - 9 p.m.'],
        ['FRI', '11 a.m. - 9 p.m.'],
        ['SAT', '11 a.m. - 9 p.m.'],
        ['SUN', '11 a.m. - 9 p.m.'],
      ],
      cursorIconScale: 1,
      phoneIconScale: 1,
      fbIconScale: 1,
      insIconScale: 1,
    }
  },
  methods: {
    goToPage: function(url) {
      window.open(url, '_blank')
    },
    cursorHoverHandler: function(isHovered) {this.cursorIconScale = (isHovered) ? 1.2 : 1},
    phoneHoverHandler: function(isHovered) {this.phoneIconScale = (isHovered) ? 1.2 : 1},
    fbHoverHandler: function(isHovered) {this.fbIconScale = (isHovered) ? 1.2 : 1},
    insHoverHandler: function(isHovered) {this.insIconScale = (isHovered) ? 1.2 : 1}
  }
}

</script>

<style>
.hidden_header {
  display: none;
}
.table-sm th, .table-sm td {
  padding-left: 0;
}
</style>
