<template>
  <div>
    <b-navbar>
      <!-- Shota Logo -->
      <b-navbar-brand href="#">
        <router-link v-bind:to="{ name: 'Home', params:{'id': '#home'}}">
          <b-img src="@/assets/image/LogoLandscape.png"/>
        </router-link>
      </b-navbar-brand>

      <!-- Bag Counter -->
      <div class="ml-auto">
        <span class="h4">Bag</span>
        <span class="text-dark"> ({{ cartCounter }} items)</span>
      </div>
    </b-navbar>
    <b-row>
      <b-col cols="auto" class="mx-auto my-2">
        <router-link v-bind:to="{ name: 'Home', params:{'id': '#home'}}">
          <span>
            Continue Shopping
          </span>
        </router-link>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ShoppingBagNavigationBar",
  computed: {
    ...mapGetters({
      cartCounter : 'cart/cartCounter',
    }),
  },
  methods: {
    routeToShoppingBag() {
      if (this.isOpen) {
        this.$router.push('/myBag#bag')
      } else {
        this.showStoreClosedAlert()
      }
    },
  }
}
</script>

<style module lang="scss">
</style>
