<template>
  <b-row class="mb-3" v-if="this.paymentMethod === 'ONLINE'">
    <b-col cols="12" md="4" class="m-auto">
      <b-alert variant="success" show>
        <b-row>
          <b-col>
            <b>
              <span>Tips Options: </span>
              <span>({{ tipPercentage | decimalToPercentageFormat }})</span>
            </b>
          </b-col>
          <b-col cols="auto">
            <b>
              <span>{{ payment.tip | priceFormat }}</span>
            </b>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-input v-model="tipPercentage"
              type="range" min="0" max="0.5" step="0.05"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="auto" class="m-auto">（Slide it to adjust）</b-col>
        </b-row>
      </b-alert>
    </b-col>
  </b-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ConfirmOrderTips",
  data() {
    return {
      tipPercentage: this.$store.getters['cart/tipPercentage'],
    }
  },
  computed: {
    ...mapGetters({
      paymentMethod: 'cart/paymentMethod',
      payment: 'cart/payment',
    })
  },
  mounted() {
    this.tipUpdate()
  },
  watch: {
    tipPercentage: function() {
      this.tipUpdate()
    }
  },
  methods: {
    tipUpdate() {
      let tip = 0;
      if (this.paymentMethod === 'ONLINE') {
        tip = Math.round(this.payment.total * this.tipPercentage);
      }
      this.$store.commit('cart/updateTip', tip);
      this.$store.commit('cart/updateTipPercentage', this.tipPercentage);
    }
  }
}
</script>

<style scoped>

</style>
