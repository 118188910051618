<template>
  <div id="app">
    <router-view></router-view>
    <CopyRight/>
  </div>
</template>

<script>
import CopyRight from "@/components/CopyRight"

export default {
  name: 'App',
  components: {
    CopyRight
  }
}
</script>

<style lang="scss">
@import 'src/assets/scss/global';

#app {
  font-family: Rubik, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}
</style>
