<template>
  <b-container>
    <b-row align-v="center">
      <b-col class="text-center">
        Copyright © {{new Date().getFullYear()}} Shota Sushi & Grill. All rights reserved.
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "CopyRight"
}
</script>

<style scoped></style>
