<template>
  <b-row class="mb-4">
    <b-col class="text-left mb-1 pr-0" cols="auto" md="12"
      v-for="item in this.categoryList" v-bind:key="item.categoryName"
      v-on:click="updateActiveCategory(item)"
    >
      <b-button variant="light" class="text-left shadow-light" style="line-height: 1;"
        href="#MenuItems"
        :disabled="!item.isActive">
        <span>{{ item.categoryName }}</span> | <span>{{ item.translations.zh }}</span>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "HomeMenuCategoryList",
  computed: {
    ...mapGetters({
      categoryList: 'menu/categoryList',
      activeCategory : 'menu/activeCategory',
    }),
  },
  mounted() {
    this.$store.dispatch('menu/getCategoryList');
  },
  methods: {
    updateActiveCategory(category) {
      this.$store.dispatch('menu/updateActiveCategory', category);
    }
  },
}
</script>

<style scoped>

</style>
