import Vue from 'vue'
import App from './App.vue'

import store from '@/vuex'
import router from '@/router'
import {config} from '@/config'
import {constant} from '@/constant'
import '@/utility'

// https://bootstrap-vue.org/
import { BootstrapVue, IconsPlugin} from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// https://github.com/constkhi/vue-simple-alert#readme
import VueSimpleAlert from 'vue-simple-alert'
Vue.use(VueSimpleAlert)

Vue.config.productionTip = false
Vue.prototype.appConfig = config
Vue.prototype.constant = constant

Vue.mixin({
  methods: {
    getAsset: function (imgName) {
      return require('./assets/image/' + imgName)
    }
  }
})

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
