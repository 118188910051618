let constant = {
    store: {
        lat: 49.2349789482802,
        lon: -123.15747175107484,
        operatingHours: [
            {open: '11:00', close: '20:25'},
            {open: '11:00', close: '20:25'},
            {open: '11:00', close: '20:25'},
            {open: '11:00', close: '20:25'},
            {open: '11:00', close: '20:25'},
            {open: '11:00', close: '20:25'},
            {open: '11:00', close: '20:25'}
        ],
        deliveryHours: {begin: 1000, end: 1245} // Represent by minute of the day, 17:00 - 20:45 ()
    },
    order: {
        paymentMethod: {
            STORE: 'Store Payment',
            ONLINE: 'Online Payment'
        },
        operationMode: {
            PICK_UP: 'Pick up at store',
            DELIVERY: 'Delivery'
        },
        status: {
            PENDING: 'NEW ORDER',
            IN_PROGRESS: 'IN PROGRESS',
            COMPLETED: 'COMPLETED'
        }
    },
    alert: {
        storeClosed: {
            title: 'Sorry, this moment isn\'t in operation hours',
            msg: 'Please kindly check our Business Hours. We hope to see you soon!',
            type: 'warning'
        },
        noPhone: {
            title: 'Please enter your phone number',
            msg: 'We will contact you only for ordering issue.',
            type: 'error'
        },
        outOfDeliveryHour: {
            title: 'Out of Delivery Hour',
            msg: 'Sorry, delivery service is available between 5:00 p.m. and 8:45 p.m.',
            type: 'error'
        },
        minimumOrder: {
            title: 'Minimum purchase Required',
            msg: 'Sorry, delivery service only applicable to orders minimum purchase of 30 dollars.',
            type: 'error'
        },
        unDeliverable: {
            title: 'Out of delivery range',
            msg: 'Sorry, this address is out of our delivery range, please choose another pick up method.',
            type: 'error'
        },
        invalidAddress: {
            title: 'Please enter valid Address',
            msg: 'Sorry, this address is out of our delivery range, please choose another pick up method.',
            type: 'warning'
        },
        noItemInBag: {
            title: 'No item in bag',
            msg: 'Please grab some food before you checkout.',
            type: 'warning'
        }
    }
}

export {constant}
