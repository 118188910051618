const API_URL = 'https://api.michaelxyz.com'

let config = {
  store: {
    businessId: '1ab92b0f-0289-5d47-a813-412e7d17e5b3',
    hasPayment: true,
  },
  michaelxyz: {
    api: {
      category: API_URL + '/category',
      product: API_URL + '/product',
      order: API_URL + '/order'
    }
  },
  google: {
    apiKey: 'AIzaSyAolHYfAPS0AVmlwdVfIIAmqGzBSOkgrF8'
  },
  stripe: {
    PublishableKey: 'pk_live_51IwKBbBMQDnUZ0JeyVTsZKlcuX453v6AjzQ5njc8mXXcbUpMUyfpJzktQOP1CCNaM4kb2JiACGVv6dswAhL6Fs7j00F2jRWlgu',
    PublishableKeyTest: 'pk_test_51IwKBbBMQDnUZ0JeLFTxkei4nZkJJzfXYRktWYNyJtmpX4Z8lP48iFKPpq7RE2SWOJfUblIZPwPvg2VzNdeqI2W8003VJltxRv'
  }
}

// Test
// let config = {
//   store: {
//     businessId: '769b8097-1d61-56e0-b360-9157b3144087',
//     hasPayment: true,
//   },
//   michaelxyz: {
//     api: {
//       category: API_URL + '/category',
//       product: API_URL + '/product',
//       order: API_URL + '/order'
//     }
//   },
//   google: {
//     apiKey: 'AIzaSyAolHYfAPS0AVmlwdVfIIAmqGzBSOkgrF8'
//   },
//   stripe: {
//     PublishableKey: 'pk_test_51IuUvOGfCPje9sFSZMTfl11HFKCiLlgSM0DsmmiJY2vnNCxfH5xhP1b2S7QZWlmGLx2GcDhiSOpAbFUOfD8Xznoe00yktFpCWU'
//   }
// }

export {config}
