<template>
  <div>
    <b-row class="title-frame">
      <b-col class="sub-title">
        Operation
      </b-col>
    </b-row>
    <hr class="divider">
    <b-row class="text-left">
      <b-col>
        <b-form-group>
          <b-form-radio
              v-model="operationMode"
              value="PICK_UP"
              size="lg"
              :inline="true"
          >
            Pick Up
          </b-form-radio>
          <b-form-radio
              v-model="operationMode"
              value="DELIVERY"
              size="lg"
              :inline="true"
          >
            Delivery
          </b-form-radio>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Delivery Info -->
    <GoogleMapAutoCompleteComponent v-if="operationMode==='DELIVERY'" />
  </div>
</template>

<script>
  import GoogleMapAutoCompleteComponent from "@/components/Google/GoogleMapAutoCompleteComponent";
  import {mapGetters} from "vuex";
  import moment from "moment";

  export default {
    name: "ShoppingBagOperation",
    components: {GoogleMapAutoCompleteComponent},
    data() {
      return {
        operationMode: this.$store.getters['cart/operationMode'], //'PICK_UP' && 'DELIVERY',
        isDeliveryEnabled: false,
      }
    },
    created() {
      let now = moment()
      let minuteOfNow = now.hour() * 60 + now.minute();
      this.isDeliveryEnabled = minuteOfNow > this.constant.store.deliveryHours.begin
          && minuteOfNow < this.constant.store.deliveryHours.end
    },
    computed: {
      ...mapGetters({
        payment: 'cart/payment'
      })
    },
    watch: {
      operationMode: function (val) {
        if (this.payment.subtotal < 3000 && val === 'DELIVERY') {
          let minimumOrder = this.constant.alert.minimumOrder
          this.$alert(
              minimumOrder.msg,
              minimumOrder.title,
              minimumOrder.type
          ).then(() => {
            this.operationMode = 'PICK_UP'
          })
        } else if ( !this.isDeliveryEnabled && val === 'DELIVERY') {
          let outOfDeliveryHour = this.constant.alert.outOfDeliveryHour
          this.$alert(
              outOfDeliveryHour.msg,
              outOfDeliveryHour.title,
              outOfDeliveryHour.type
          ).then(() => {
            this.operationMode = 'PICK_UP'
          })
        } else if (val === 'PICK_UP') {
          this.$store.commit('cart/updateDeliveryApt', '')
          this.$store.commit('cart/updateDeliveryAddress', '')
          this.$store.dispatch('cart/updateDeliveryFee', 0)
        }

        // Delivery will take $4 dollar delivery fee
        if (val === 'DELIVERY') {
          this.$store.dispatch('cart/updateDeliveryFee', 400)
        } else {
          this.$store.dispatch('cart/updateDeliveryFee', 0)
        }

        this.$store.commit('cart/updateOperationMode', this.operationMode)
      },
      payment: function (val) {
        if (val.subtotal < 3000) {
          this.$store.commit('cart/updateOperationMode', 'PICK_UP')
          this.operationMode = 'PICK_UP'
        }
      }
    },

  }
</script>

<style scoped>
  .title-frame {
    height: 60px;
  }
  .sub-title {
    font-size: 30px;
    font-weight: 700;
    text-align: left;
    margin-top: auto;
  }
  .divider {
    height: .20rem;
    background: #1c1c1c;
    border: none;
    margin: 0 0 10px 0;
  }
</style>
