<template>
  <b-row v-if="payment.subtotal >= 6000 && useCoupon === false">
    <b-col>
      <b-alert show variant="success">
        <h4 class="alert-heading">Special Offer Eligible!</h4>
        <p>
          With minimum purchase of $60 Limited Time Only
        </p>
        <b-row class="mb-3">
          <b-col cols="12" md="10" class="mx-auto">
            <b-row>
              <b-col cols="12" class="pr-0">
                <b-row>
                  <b-col cols="4" class="mx-auto">
                    <b-img :src="this.couponItem.image.src" fluid></b-img>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="10" class="text-center mx-auto">
                <b-row>
                  <b-col class="title text-center">
                    {{ couponItem.productName }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="sub-title text-center">
                    {{ couponItem.translations.zh }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    {{ couponItem.productDescription }}
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-form-checkbox
          v-model="useCoupon"
          value="true"
          unchecked-value="false"
        >
          Check to have a FREE Kerrisdale Roll
        </b-form-checkbox>
        <hr>
        <p class="mb-0">
          <small>
            Before tax 1 coupon per order.
            Not valid with any other offer. No cash value.
          </small>
        </p>
      </b-alert>
    </b-col>
  </b-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ShoppingBagCoupon",
  data() {
    return {
      couponItem: this.$store.getters['cart/couponItem'],
    }
  },
  computed: {
    ...mapGetters({
      payment: 'cart/payment',
    }),
    useCoupon: {
      get () {return this.$store.getters['cart/useCoupon']},
      set (val) {this.$store.commit('cart/updateUseCoupon', val)}
    }
  },
  watch: {
    payment: function (val) {if (val.subtotal < 6000) this.useCoupon = false},
  }

}
</script>

<style scoped>
.title {
  font-size: 20px;
  font-weight: 500;
}
.sub-title {
  font-size: 18px;
  font-weight: 450;
}
</style>
