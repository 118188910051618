<template>
  <b-row>
    <b-col cols="12" md="6">
      <b-row :class="$style['sub-label']">
        <b-col>Operation Mode:</b-col>
      </b-row>
      <b-row :class="$style['sub-title']">
        <b-col>
          <b>{{ this.constant.order.operationMode[operationMode] }}</b>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" md="6" v-if="operationMode === 'PICK_UP'">
      <b-row :class="$style['sub-label']">
        <b-col>Shota Sushi & Gill Address:</b-col>
      </b-row>
      <b-row :class="$style['sub-title']">
        <b-col>
          <b>5688 Yew St, Vancouver, BC V6M 3Y3</b>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" md="6" v-else-if="operationMode === 'DELIVERY'">
      <b-row :class="$style['sub-label']">
        <b-col>Delivery address:</b-col>
      </b-row>
      <b-row :class="$style['sub-title']">
        <b-col>
          <b>
            <span v-if="info.delivery.aptNumber">
              {{ info.delivery.aptNumber }} -
            </span>
            <span>
              {{ info.delivery.address }}
            </span>
          </b>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" md="6">
      <b-row :class="$style['sub-label']">
        <b-col>Phone:</b-col>
      </b-row>
      <b-row :class="$style['sub-title']">
        <b-col>
          <b>{{ info.phone }}</b>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" md="6">
      <b-row :class="$style['sub-label']">
        <b-col>Payment Method:</b-col>
      </b-row>
      <b-row :class="$style['sub-title']">
        <b-col>
          <b>{{ this.constant.order.paymentMethod[paymentMethod] }}</b>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" md="6" v-if="info.comment">
      <b-row :class="$style['sub-label']">
        <b-col>Comment:</b-col>
      </b-row>
      <b-row :class="$style['sub-title']">
        <b-col>
          <b>{{ info.comment }}</b>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "ConfirmOrderInfo",
  props: {
    info: {
      required: true,
      type: Object,
    },
    paymentMethod: {
      required: true,
      type: String,
    },
    operationMode: {
      required: true,
      type: String,
    },
  },
}
</script>

<style module lang="scss">
.sub-label {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}
.sub-title {
  font-size: 25px;
  font-weight: 500;
  text-align: left;
  margin-top: auto;
}
</style>
