<template>
  <div ref="map" class="w-100 h-100"></div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
const GOOGLE_MAP_ZOOM = 15;
const GOOGLE_MAP_TYPE = 'roadmap';
const GOOGLE_MAP_STYLE = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#242f3e"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#746855"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#242f3e"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#d59563"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#d59563"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#263c3f"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#6b9a76"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#38414e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#212a37"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9ca5b3"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#746855"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#1f2835"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#f3d19c"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#2f3948"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#d59563"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#17263c"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#515c6d"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#17263c"
      }
    ]
  }
];
const GOOGLE_MAP_LIBRARIES = ['places'];
const GOOGLE_MAP_MARKER = 'LogoMapPointWhiteBg.png';

export default {
  name: "GoogleMapViewComponent",
  data() {
    return {
      google: null,
      mapOptions: {
        center: {
          lat: this.constant.store.lat,
          lng: this.constant.store.lon
        },
        zoom: GOOGLE_MAP_ZOOM,
        type: GOOGLE_MAP_TYPE,
        styles: GOOGLE_MAP_STYLE
      },
      map: null,
      storeLocation: {
        lat: this.constant.store.lat,
        lng: this.constant.store.lon,
      }
    }
  },
  async mounted() {
    const googleApi = new Loader({
      apiKey: this.appConfig.google.apiKey,
      libraries: GOOGLE_MAP_LIBRARIES,
    });

    this.google = await googleApi.load();
    this.google = window.google
    this.initAutocomplete()
    this.setMarker(this.storeLocation)
  },
  methods: {
    initAutocomplete() {
      this.map = new this.google.maps.Map(this.$refs.map, this.mapOptions);
    },
    setMarker(point) {
      new this.google.maps.Marker({
        position: point,
        map: this.map,
        icon: this.getAsset(GOOGLE_MAP_MARKER)
      })
    }
  }
}
</script>

<style scoped>

</style>
