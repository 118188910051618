<template>
  <b-row id="Special" :class="$style['background-img']">
    <b-col cols="3">

    </b-col>
    <b-col cols="12" md="6">
      <b-row class="mt-5 mb-3">
        <b-col class="text-center h2" style="color: red;">
          <b>Special Offer</b>
        </b-col>
      </b-row>
      <b-row class="mb-2 mb-md-5 text-center">
        <b-col>
          <b-row class="mt-2">
            <b-col class="h2" style="color: red">
              Free Kerrisdale Roll
            </b-col>
          </b-row>
          <b-row>
            <b-col class="h5">
              With minimum purchase of $60
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col>
              <b>Limited Time Only</b>
            </b-col>
          </b-row>
          <b-row class="mb-0 mb-md-4">
            <b-col class="h6 font-weight-light">
              Before tax 1 coupon per person.
              <br/>
              Not valid with any other offer. No cash value.
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="3" class="d-none d-md-block mt-4">
      <b-img
          :src="getAsset('Logo.png')"
          width="auto"
          height="100" />
    </b-col>
  </b-row>
</template>

<script>
  export default {
    name: "HomeMenuSpecial"
  }
</script>

<style module lang="scss">
  .background-img {
    background-image: url("~@/assets/image/water.jpg");
    background-position: 50% 100%;
    background-size: cover;
  }
</style>
